.Popover {
	padding: var(--container-padding-xs) var(--container-padding-sm);
	background-color: var(--base-color-dark);
	border-radius: var(--border-radius-sm);
	color: white;
	z-index: var(--popover-z);
}

.Trigger {
	display: inline-block;
	cursor: pointer;
}

.NoWrap {
	text-wrap: nowrap;
}
