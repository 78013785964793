.Select div {
	border-radius: var(--form-field-border-radius);
}

/* this focus isn't working */
.Select > div:first-of-type:hover,
.Select > div:first-of-type:focus {
	border: 1px solid var(--color-violet-7);
	background-color: var(--color-grey-1);
	color: var(--color-violet-8);
}

.Select span {
	width: 0;
}

.NoWrap {
	flex-wrap: nowrap !important;
}

.Select svg {
	fill: var(--color-grey-9);
}
