.RowClassName {
	cursor: pointer;
}

.Filters {
	display: flex;
}

.Filters > * {
	margin-right: 1rem;
}

.TableContainer {
	width: 100%;
}

.Table [class*='rs-table-row-expanded'] {
	height: auto;
	padding: 0;
	border-top: none;
	border-left: 1px solid #e0e0e0;
	left: 3.125rem;
}

.ExpandButton {
	border: none;
	color: transparent;
}

.ExpandButton:hover {
	background-color: transparent;
}
