.Grid {
	display: grid;
	justify-content: space-between;
}

.FullWidth {
	width: 100%;
}

.GridGapSm {
	grid-gap: 0.75rem;
}

.GridGapMd {
	grid-gap: 1.5rem;
}

.GridGapLg {
	grid-gap: 2.25rem;
}
