.DayToday {
	background: rgba(180 180 180 / 25%);
	border-radius: var(--border-radius-sm);
}

.DaySelected {
	background: var(--primary-color-light);
	border-radius: var(--border-radius-sm);
	color: white;
}

.RangeStart {
	background: var(--primary-color-light);
	border-radius: var(--border-radius-full) 0 0 var(--border-radius-full);
}

.RangeMiddle {
	background: var(--primary-color-light);
	border-radius: 0;
}

.RangeEnd {
	background: var(--primary-color-light);
	border-radius: 0 var(--border-radius-full) var(--border-radius-full) 0;
}

.Chevron {
	fill: rgba(180 180 180 / 90%);
}

.Calendar {
	min-width: 20rem;
}
