.Popover {
	width: 100%;
}

.Trigger {
	border: 1px solid var(--formfield-border-color);
	border-radius: var(--form-field-border-radius);
	box-sizing: border-box;
	color: var(--base-color-dark);
	font-size: 1rem;
	line-height: 1.375rem;
	padding: 0.75rem 1rem;
	transition: 0.2s;
	width: 100%;
	outline: none;
	background: unset;
	text-align: left;
}

.Trigger:hover {
	border: 1px solid var(--formfield-hover-border-color);
	background-color: var(--formfield-hover-background-color);
	color: var(--formfield-hover-color);
}

.NoWrap {
	text-wrap: nowrap;
}
