.Dropdown {
	z-index: calc(var(--top-bar-z) - 10);
	box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%);
	border-radius: var(--border-radius-md);
	background: white;
}

.Button {
	padding: 0.2rem 0.5rem;
	border-radius: 0.5rem;
	background-color: var(--base-color-lightest);
}

.Dropdown > ul {
	list-style: none;
}

.Dropdown li {
	white-space: nowrap;
	padding: 0.5rem;
	width: 90%;
	height: 100%;
	margin-bottom: 0.25rem;
}

.Dropdown li:hover,
.Dropdown li:focus {
	padding: 0.5rem;
	background-color: var(--color-violet-2);
	border-radius: 0.25rem;
}

.Dropdown a {
	color: var(--color-grey-9);
}

.Dropdown a:hover,
.Dropdown a:focus {
	color: var(--color-grey-12);
}
